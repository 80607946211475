import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';


//const smtpClient = new SMTPClient({
//  user: 'stefkpkq',
//  password: 'cRufZHl1tCz9',
//  host: 'smtp.contact@steffygraphy.co.uk',
//  ssl: true,
//});

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})


export class ContactComponent {
  @ViewChild('senderName', { static: false }) senderName: ElementRef;
  @ViewChild('message', { static: false }) message: ElementRef;
  @ViewChild('sendButton', { static: false }) sendButton: ElementRef;


  sendMessage(name: string,  message: string) {
    if (!name || !message) {
      alert("Please enter all details");
      return;
    }

    this.sendButton.nativeElement.href = "mailto:contact@steffygraphy.co.uk?subject=Steffygraphy: Message from " + name + "&body=" + message;
    this.senderName.nativeElement.value = "";
    this.message.nativeElement.value = "";
  }

}
