import { Component, OnInit } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  private _albums: Array<{ src: string, thumb: string }> = [];

  constructor(private _lightbox: Lightbox) {

    for (let i = 1; i <= this.slideshowImages.length; i++) {
      const src = 'assets/images/gallery/' + i + '.jpg';
      const thumb = 'assets/images/gallery/' + i + '.jpg';
      const album = {
        src: src,
        thumb: thumb
      };

      this._albums.push(album);
    }
  }

  slideshowImages: Array<string> = [
    'assets/images/gallery/1.jpg',
    'assets/images/gallery/2.jpg',
    'assets/images/gallery/3.jpg',
    'assets/images/gallery/4.jpg',
    'assets/images/gallery/5.jpg',
    'assets/images/gallery/6.jpg',
    'assets/images/gallery/7.jpg',
    'assets/images/gallery/8.jpg',
    'assets/images/gallery/9.jpg'
  ];

  ngOnInit() {
  }

  open(index: number): void {
    this._lightbox.open(this._albums, index, { fadeDuration: 0.2, resizeDuration: 0.2, centerVertically: true });
  }

  close(): void {
    this._lightbox.close();
  }
}
