import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@
Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('translateLeftToRight', [

      state('left', style({
        visibility: 'hidden',
        opacity: 0.2,
        transform: 'translateX(-6%)'
      })),
      state('right', style({
        visibility: 'visible',
        opacity: 1,
        transform: 'translateX(0%)'
      })),
      transition('left=>right', animate('600ms'))
    ]),

    trigger('translateRightToLeft', [

      state('right', style({
        visibility: 'hidden',
        opacity: 0.2,
        transform: 'translateX(6%)'
      })),
      state('left', style({
        visibility: 'visible',
        opacity: 1,
        transform: 'translateX(0%)'
      })),
      transition('right=>left', animate('600ms'))
    ])


    ]
})


export class HomeComponent implements OnInit {
  @ViewChild('welcome', { static: true }) welcome: ElementRef;


  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.pageYOffset + window.innerHeight;

    if (this.welcome && this.welcome.nativeElement.offsetTop <= scrollPosition) {
      this.changeState();
    }
  }
  
  slideshowImages: Array<string> = [
    'assets/images/slideshow/1.jpg',
    'assets/images/slideshow/2.jpg',
    'assets/images/slideshow/3.jpg',
    'assets/images/slideshow/4.jpg',
    'assets/images/slideshow/5.jpg',
    'assets/images/slideshow/6.jpg',
    'assets/images/slideshow/7.jpg',
    'assets/images/slideshow/8.jpg',
    'assets/images/slideshow/9.jpg'
  ];

  leftToRight = 'left';
  rightToLeft = 'right';

  ngOnInit() {
  }

  changeState() {
    this.leftToRight = 'right';
    this.rightToLeft = 'left';
  }

}
