import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css'],
  animations: [
    trigger('fadein', [
      state('in', style({ opacity: 1 })),
      state('out', style({ opacity: 0 })),
      transition('out=>in', animate('300ms'))
    ])]
})

export class BlogComponent implements OnInit {

  constructor() {
  }
  

  ngOnInit() {
  }
}
