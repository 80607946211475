import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { SlideshowModule } from 'ng-simple-slideshow';
import { GalleryComponent } from "./gallery/gallery.component";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BlogComponent } from "./blog/blog.component";
import { Sigma70mmComponent } from './blog/content/sigma70mm/sigma70mm.component';
import { LightboxModule } from 'ngx-lightbox';
//import { DisqusModule } from 'ngx-disqus';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { environment } from './../environments/environment';

const cookieConfig: NgcCookieConsentConfig = {
  "cookie": {
    "domain": 'steffygraphy.co.uk'
  },
  "position": "bottom",
  "theme": "classic",
  "palette": {
    "popup": {
      "background": "#000000",
      "text": "#ffffff",
      "link": "#ffffff"
    },
    "button": {
      "background": "#eebb4d",
      "text": "#000000",
      "border": "transparent"
    }
  },
  "type": "info",
  "content": {
    "message": "This website uses cookies to ensure you get the best experience on our website.",
    "dismiss": "Got it!",
    "deny": "Refuse cookies",
    "link": "Learn more",
    "href": "https://cookiesandyou.com",
    "policy": "Cookie Policy"
  }
}

@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    HomeComponent,
    GalleryComponent,
    BlogComponent,
    Sigma70mmComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    SlideshowModule,
    BrowserAnimationsModule,
    NgbModule,
    LightboxModule,
    //DisqusModule.forRoot('disqus'),
    NgcCookieConsentModule.forRoot(cookieConfig),

    RouterModule.forRoot([
      { path: 'gallery', component: GalleryComponent },
      { path: 'blog', component: BlogComponent },
      { path: 'blog/Review-of-Sigma-70mm-f2.8-macro-lens-for-Sony-E-mount', component: Sigma70mmComponent },
      { path: 'contact', component: ContactComponent },
      { path: '**', component: HomeComponent }
    ]),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
