import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from "./contact/contact.component";
import { HomeComponent } from "./home/home.component";
import { GalleryComponent } from "./gallery/gallery.component";
import { BlogComponent } from "./blog/blog.component";
import { Sigma70mmComponent } from './blog/content/sigma70mm/sigma70mm.component';
//import { DisqusModule } from 'ngx-disqus';

const routes: Routes = [
  { path: 'contact', component: ContactComponent },
  { path: 'gallery', component: GalleryComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blog/Review-of-Sigma-70mm-f2.8-macro-lens-for-Sony-E-mount', component: Sigma70mmComponent },
  { path: '**', component: HomeComponent}
];

@NgModule({
  imports: [
    //DisqusModule.forRoot('disqus'),
    RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
